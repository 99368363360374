import { Container, Header, Loader } from "semantic-ui-react";
import { VerticalTimeline, VerticalTimelineElement }  from "react-vertical-timeline-component";
import { TbCertificate } from "react-icons/tb";
import { useEffect, useState } from "react";
import axios from "axios";

import "semantic-ui-css/semantic.min.css";
import "react-vertical-timeline-component/style.min.css";

import { API_HOST } from '../constants';

function CertificationsItem(props) {
    let icon = <TbCertificate />;
    return (
        <VerticalTimelineElement
                className={`vertical-timeline-element--${props.type}`}
                contentStyle={{ border: "1px solid #000" }}
                contentArrowStyle={{ borderRight: "7px solid #000" }}
                date={props.date}
                iconStyle={{ background: "#fff", border: "0px" }}
                icon={icon}
            >
            <Header as="h3" style={{margin:0}}>{props.title}</Header>

            <div style={{margin:"10px 30px"}}
                dangerouslySetInnerHTML={{__html: props.description}}>
            </div>
            <div>
                {/* Tags: [<ul>{props.tags.toString().split(",").map((tag) => <li>{tag}</li>)}</ul>] */}
                <pre><b>Issuer(s): </b>{props.tags.toString().replace(",", "  |  ")}
                <br></br>
                <b>Verify ID: </b><a style={{margin:"50px 0px"}} href={props.url} target="_blank" rel="noreferrer">{props.credentialId}</a></pre>
            </div>
        </VerticalTimelineElement>
    )
}

export default function Certifications() {

    let [loading, setLoading] = useState(true);
    let [certifications, setCertifications] = useState([]);

    function loadCertifications() {
        setLoading(true);
        let url = `${API_HOST}/certifications.json`;
        axios.get(url).then((response) => {
            setLoading(false);
            if(response.status !== 200) {
                setCertifications(require("../data/certifications.json"));
            }
            setCertifications(response.data);
        }).catch(() => {
            setLoading(false);
            setCertifications(require("../data/certifications.json"));
        });
    }

    useEffect(() => loadCertifications(), []);
    return (
        <div>
            <Container text textAlign="justified">
                <Header as="h1">Featured Certifications</Header>
                <p>In this section, I list down some of my certifications. To get the full list, visit my <a href="https://www.linkedin.com/in/adityapal1/">Linkedin</a>.</p>
                <br></br>
            </Container>
            {
                loading
                ?<Loader active size="medium" inline style={{margin: "100px 0px"}}>Loading Certifications</Loader>:
                <VerticalTimeline lineColor="black" layout="1-column-left">
                    {
                        certifications.map(
                            (certifications, i) => (
                                <CertificationsItem key={i} {...certifications} />
                            )
                        )
                    }
                </VerticalTimeline>
            }
        </div>
    );
}