import { Container, Header, Loader } from "semantic-ui-react";
import { VerticalTimeline, VerticalTimelineElement }  from "react-vertical-timeline-component";
import { GiMaterialsScience } from "react-icons/gi";
import { useEffect, useState } from "react";
import axios from "axios";

import "semantic-ui-css/semantic.min.css";
import "react-vertical-timeline-component/style.min.css";

import { API_HOST } from '../constants';

function ReadingItem(props) {
    let icon = <GiMaterialsScience />;
    return (
        <VerticalTimelineElement
                className={`vertical-timeline-element--${props.type}`}
                contentStyle={{ border: "1px solid #000" }}
                contentArrowStyle={{ borderRight: "7px solid #000" }}
                date={props.date}
                iconStyle={{ background: "#fff", border: "0px" }}
                icon={icon}
            >
            <Header as="h3" style={{margin:0}}>{props.title}</Header>

            <div style={{margin:"10px 30px"}}
                dangerouslySetInnerHTML={{__html: props.description}}>
            </div>
            <div>
                {/* Tags: [<ul>{props.tags.toString().split(",").map((tag) => <li>{tag}</li>)}</ul>] */}
                {/* Tags: {props.tags.toString()} */}
            </div>
            <a style={{margin:"50px 50px"}} href={props.url} target="_blank" rel="noreferrer">Read more</a>
            <br></br>
        </VerticalTimelineElement>
    )
}

export default function Reading() {

    let [loading, setLoading] = useState(true);
    let [reading, setReading] = useState([]);

    function loadReading() {
        setLoading(true);
        let url = `${API_HOST}/reading.json`;
        axios.get(url).then((response) => {
            setLoading(false);
            if(response.status !== 200) {
                setReading(require("../data/reading.json"));
            }
            setReading(response.data);
        }).catch(() => {
            setLoading(false);
            setReading(require("../data/reading.json"));
        });
    }

    useEffect(() => loadReading(), []);
    return (
        <div>
            <Container text textAlign="justified">
                <Header as="h1">Featured Reading</Header>
                <p>In this section, I list down some of my paper readings. I spend some time every week going through research articles from top conferences, which helps me stay updated on ML research.</p>
                <br></br>
            </Container>
            {
                loading
                ?<Loader active size="medium" inline style={{margin: "100px 0px"}}>Loading Reading</Loader>:
                <VerticalTimeline lineColor="black" layout="1-column-left">
                    {
                        reading.map(
                            (reading, i) => (
                                <ReadingItem key={i} {...reading} />
                            )
                        )
                    }
                </VerticalTimeline>
            }
        </div>
    );
}