import { Container, Segment, Header } from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";

export default function SiteHeader() {
    return (
        <Segment size="big" attached="top">
            <Container text>
                <Header size="large">Aditya Pal</Header>
            </Container>
        </Segment>
    );
}