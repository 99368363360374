// import { useState } from "react";
import { Container } from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";

export default function Introduction() {
    return (
        <div style={{"padding": "1% 0"}}>
            <Container text>
                {/* <Header as="small">I am</Header> */}
                <p style={{"paddingTop": "10px"}}>
                I am Aditya, a <b>CS grad student at <a href="https://www.gatech.edu/" target="_blank" rel="noreferrer">Georgia Institute of Technology</a></b> with a passion for Machine Learning research & provable experience in building large-scale AI Systems. I have been a <b>Teaching Assistant</b> for one of GaTech's most popular courses <a href="https://omscs.gatech.edu/cs-7641-machine-learning" target="_blank" rel="noreferrer">CS 7641: Machine Learning</a>, where I enhanced students' ML knowledge by conducting office hours & setting / evaluating assignments & exams.
                </p>
                <p>
                As an SDE Machine Learning co-op intern at <b><a href="https://www.modernatx.com/en-US" target="_blank" rel="noreferrer">Moderna Therapeutics</a></b>, I am building large-scale event engine for the firm's ML Compute Platform. The system is expected to handle 1 million notification events per day & is a critical component for orchestration of ML workloads. <br></br>Previously, as a Senior Software Developer in a global <a href="https://www.goldmansachs.com/what-we-do/transaction-banking/" target="_blank" rel="noreferrer">Transaction Banking</a> Platform team at <b><a href="https://www.goldmansachs.com" target="_blank" rel="noreferrer">Goldman Sachs</a></b>, I led ML efforts for Fraud Detection, API usage anomaly detection & built internal chatbots using LLMs, Langchain, RAGs, vector DBs & prompt tuning. I also built scalable, large-scale, robust & SRE-backed systems using my skills in AWS, Kafka, Terraform, Java & Python. 
                </p>
                <p>
                I am a passionate learner & an avid MOOC enthusiast with certifications on AI, ML, cloud computing & big data. I am currently working on building <a href="https://github.com/git-disl/GPTLens" target="_blank" rel="noreferrer">adversarial LLMs</a> to detect software vulnerabilities in Blockchain codebases, as part of the Data Systems Research Group advised by <a href="https://faculty.cc.gatech.edu/~lingliu/" target="_blank" rel="noreferrer">Prof. Ling Liu</a>. Previously, I worked with <a href="https://www.bitmesra.ac.in/Display_My_Profile_00983KKj893L?id=pHDc7Pk60Yh4JceoadzlSQ%253d%253d" target="_blank" rel="noreferrer">Prof. Abhijit Mustafi</a> to apply NLP techniques for <a href="https://ieeexplore.ieee.org/document/9276893" target="_blank" rel="noreferrer">movie genre prediction</a> & <a href="https://arxiv.org/abs/2012.07652" target="_blank" rel="noreferrer">LLMs for low-resources languages</a>. My goal is to constantly improve my knowledge & apply them to solve real-world problems & challenges.
                </p>
                <p>
                In my free time, I like to travel, play games (board games, multiplayer FPS games) & watch movies (mostly in theatres). A fun fact about me: My name is an anagram of "<b>I Play Data</b>".
                </p>
                <p>
                    Check out my: 
                    <ul>
                        <li><a href="https://www.linkedin.com/in/adityapal1/" target="_blank" rel="noreferrer">Linkedin profile</a></li>
                        <li><a href="https://github.com/sciencepal" target="_blank" rel="noreferrer">Github projects</a></li>
                        <li><a href="https://scholar.google.com/citations?user=OLJ0_4kAAAAJ&hl=en">Publications</a></li>
                        <li><a href="https://drive.google.com/file/d/11No_Olvk3l9r3V0CCP2Sm1FhhmDwDow7/view?usp=drive_link">Resume</a></li>
                    </ul>
                </p>
            </Container>
        </div>
    );
}